import { BuildingOffice2Icon, MapIcon, PhotoIcon, StarIcon, TrophyIcon } from '@heroicons/react/24/outline'

import { type ApiPlatformEntity, type ApiPlatformListResponse } from '@interfaces/api'
import { type Course } from '@interfaces/api/course/course'
import { type Image } from '@interfaces/api/image'

export interface Artwork extends ApiPlatformEntity {
  cover: Image
  name: string
  uid: string
}

export interface Experience extends ApiPlatformEntity {
  city: string
  name: string
  specificData?: Partial<Course>
  type: string
  uid: string
}

export interface ExperienceFull extends Experience {
  city: string
  cover: Image
  name: string
  type: string
  uid: string
}

export enum ExperienceType {
  activity = 'activity',
  artwork = 'artwork',
  attraction = 'attraction',
  course = 'course',
  hotel = 'hotel'
}

export const ExperienceIcon: Record<ExperienceType, typeof PhotoIcon> = {
  activity: TrophyIcon,
  artwork: PhotoIcon,
  attraction: StarIcon,
  course: MapIcon,
  hotel: BuildingOffice2Icon
}

export const getExperienceIcon = (type: string) => {
  switch (type) {
    case 'activity':
      return ExperienceIcon.activity
    case 'artwork':
      return ExperienceIcon.artwork
    case 'attraction':
      return ExperienceIcon.attraction
    case 'course':
      return ExperienceIcon.course
    case 'hotel':
      return ExperienceIcon.hotel
    default:
      return ExperienceIcon.attraction
  }
}

export enum ExperienceColors {
  artwork = 'bg-green-400',
  attraction = 'bg-blue-400',
  course = 'bg-purple-400'
}

export enum DurationUnit {
  day = 'day',
  hour = 'hour',
  minute = 'minute',
  week = 'week'
}

export enum AttractionType {
  audioGuide = 'audio_guide',
  hotel = 'hotel'
}

export type ExperiencesResponse = ApiPlatformListResponse<Experience>
