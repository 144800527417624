import { type ApiPlatformEntity } from '@interfaces/api'

export enum PartnerOrderState {
  cancelled = 'cancelled',
  cart = 'cart',
  fulfilled = 'fulfilled',
  new = 'new'
}

export enum PartnerOrderShippingState {
  cart = 'cart',
  shipped = 'shipped'
}

export enum PartnerOrderCheckoutState {
  cart = 'cart',
  completed = 'completed'
}

export enum PartnerOrderPaymentState {
  awaiting_payment = 'awaiting_payment',
  cancelled = 'cancelled',
  cart = 'cart',
  paid = 'paid',
  refunded = 'refunded'
}

export interface PartnerOrder extends ApiPlatformEntity {
  cancelledAt?: string
  checkoutCompletedAt?: string
  checkoutState: PartnerOrderCheckoutState
  createdAt: string
  currency: string
  fulfilledAt?: string
  paidAt?: string
  partner: string
  paymentState: PartnerOrderPaymentState
  shippingState: PartnerOrderShippingState
  state: PartnerOrderState
  target: string
  totalPrice: number
  uid: string
  updatedAt: string
}
