import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import ApiResourceCreate from '@components/api-resource/api-resource-create'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import ContextualButton from '@components/buttons/contextual-button'
import EmptyStateButton from '@components/empty-state-button/empty-state-button'
import {
  type ExperienceCourseStepsProps
} from '@components/experiences/experience-course-steps/experience-course-steps.interfaces'
import Modal from '@components/modals/modal'
import SkeletonLoaderTable from '@components/skeleton-loader/skeleton-loader-table'
import { type CourseNavigationStep, CourseStepType } from '@interfaces/api/course/course-step'
import useApiResource from '@services/api/definition'
import useDeleteEntrySimple from '@services/api/resources/delete-entry-query-simple'
import useItemChildrenQuery from '@services/api/resources/item-children'
import usePatchEntrySimple from '@services/api/resources/patch-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'

const ExperienceCourseSteps = ({ course }: ExperienceCourseStepsProps) => {
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'navigation-steps' })

  const [open, setOpen] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [navigationStep, setNavigationStep] = useState<CourseNavigationStep>()

  const [childrens, setChildrens] = useState<CourseNavigationStep[]>([])
  const collectionDefinition = useApiResource('navigation-steps')
  const definition = useApiResource('navigation-steps/{uid}')

  const { mutateAsync: patchResourceEntry } = usePatchEntrySimple()
  const { mutateAsync: deleteResourceEntry } = useDeleteEntrySimple()

  const {
    data: {
      data: navigationSteps
    } = {},
    isFetching,
    refetch
  } = useItemChildrenQuery<CourseNavigationStep>({
    itemId: course.uid,
    path: 'courses/{uid}/navigation-steps'
  })

  useEffect(() => {
    if (navigationSteps) {
      setChildrens(navigationSteps)
    }
  }, [navigationSteps])

  const onAddNavigationStepClick = () => {
    setOpen(true)
  }

  const reload = () => {
    refetch().catch(captureException)
  }

  const onDeleteClick = (navigationStep: CourseNavigationStep) => {
    setOpenDeleteModal(true)
    setNavigationStep(navigationStep)
  }

  const onDeleteHandler = () => {
    if (navigationStep) {
      deleteResourceEntry({ id: navigationStep.uid, path: 'navigation-steps/{uid}' }).then(() => {
        reload()
        setOpenDeleteModal(false)
      }).catch(captureException)
    }
  }

  const onEditClick = (navigationStep: CourseNavigationStep) => {
    setNavigationStep(navigationStep)
    setOpenEdit(true)
  }

  const onCancelDeleteClick = () => {
    setOpenDeleteModal(false)
  }

  const beforeSubmit = (data) => {
    data.course = course['@id']

    return data
  }

  const onCreate = async () => {
    await refetch()
    setOpen(false)
  }
  const onEdit = async () => {
    await refetch()
    setOpenEdit(false)
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const sourceIndex = result.source.index
    const destinationIndex = result.destination.index
    const newPriority = childrens[result.destination.index].priority

    if (sourceIndex === destinationIndex) {
      return
    }

    const newNavigationSteps = Array.from(childrens)
    const [removed] = newNavigationSteps.splice(sourceIndex, 1)

    newNavigationSteps.splice(destinationIndex, 0, removed)
    setChildrens(newNavigationSteps)

    patchResourceEntry({
      data: {
        priority: newPriority
      },
      id: result.draggableId,
      path: 'navigation-steps/{uid}'
    }).then(() => {
      reload()
    }).catch(captureException)
  }

  return (
    <>
      <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-10'>
        <div className='px-4 py-4'>
          <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
            <div className='ml-4 mt-4'>
              <h3 className='text-xl font-semibold leading-6 text-slate-700'>Étapes de navigation</h3>
            </div>

            <div className='ml-4 mt-4 flex-shrink-0'>
              <ContextualButton onClick={onAddNavigationStepClick}>
                {translateActions('addNavigationStep')}
              </ContextualButton>
            </div>
          </div>
        </div>

        {childrens.length > 0
          ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable direction='vertical' droppableId='droppable'>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    className='flow-root'
                    ref={provided.innerRef}
                  >
                    <div className='w-screen sm:w-auto'>
                      <div className={'min-w-min w-full table-wrp block'}>
                        <table className='w-full divide-y'>
                          <thead className='bg-gray-50 text-gray-900 sticky top-0'>
                            <tr>
                              <th className='p-4 text-left'>
                                <div className='flex items-center text-base font-medium'>
                                  Nom
                                </div>
                              </th>

                              <th />
                            </tr>
                          </thead>

                          <tbody className='bg-white min-h-[400px] mb-20'>
                            {isFetching
                              ? (
                                <SkeletonLoaderTable headers={[]} />
                              )
                              : (
                                <>
                                  {childrens.map((navigationStep, index) => (
                                    <Draggable draggableId={navigationStep.uid} index={index} key={navigationStep.uid}>
                                      {(provided, snapshot) => (
                                        <tr
                                          className='flex items-center bg-white hover:bg-gray-100 cursor-pointer'
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            onEditClick(navigationStep)
                                          }}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          key={navigationStep.uid}
                                        >
                                          <td className='p-4'>
                                            {navigationStep.type === CourseStepType.ICON && navigationStep.icon && (
                                              <img alt='Nav step' className='w-10 p-2 bg-slate-100 rounded-md' src={`/assets/navigation-steps/${navigationStep.icon}.png`} />
                                            )}

                                            {navigationStep.type === CourseStepType.ARTWORK && navigationStep.artwork && (
                                              <>
                                                {navigationStep.artwork?.cover?.url
                                                  ? (
                                                    <img alt='Artwork cover' className='w-10 rounded-md' src={navigationStep.artwork.cover.url} />
                                                  )
                                                  : (
                                                    <div className='w-10 h-14 rounded-md bg-slate-200' />
                                                  )}
                                              </>
                                            )}
                                          </td>

                                          <td className='p-4 flex-grow'>
                                            {navigationStep.title}
                                          </td>

                                          <td className='p-4 flex space-x-3 ml-auto'>
                                            <button
                                              className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white'
                                              onClick={(e) => {
                                                e.stopPropagation()
                                                onEditClick(navigationStep)
                                              }}
                                            >
                                              <PencilSquareIcon className='w-5 h-5 mx-auto' />
                                            </button>

                                            <button
                                              className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white'
                                              onClick={(e) => {
                                                e.stopPropagation()
                                                onDeleteClick(navigationStep)
                                              }}
                                            >
                                              <TrashIcon className='w-5 h-5 mx-auto' />
                                            </button>
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )
          : (
            <div className='py-4 pb-8 px-4 w-full'>
              <EmptyStateButton onClick={onAddNavigationStepClick} translateKey={'navigation-steps'} />
            </div>
          )}

        <Modal center open={open} setOpen={setOpen} size='large' title={translateResource('create')}>
          {collectionDefinition && <ApiResourceCreate beforeSubmit={beforeSubmit} definition={collectionDefinition} fieldsToHide={['priority']} isModal onEdit={onCreate} />}
        </Modal>

        {navigationStep && (
          <Modal center open={openEdit} setOpen={setOpenEdit} size='large' title={translateResource('edit')}>
            {definition && <ApiResourceEdit definition={definition} fieldsToHide={['priority']} id={navigationStep.uid} isModal onEdit={onEdit} />}
          </Modal>
        )}

        <Modal center open={openDeleteModal} setOpen={setOpenDeleteModal} title={translateActions('deleteResource')}>
          <div className='flex flex-col items-center p-8'>
            <div className='text-center text-xl'>{translateActions('deleteConfirmation')}</div>

            <div className='flex mt-6 gap-8'>
              <ContextualButton onClick={onDeleteHandler} style='warning'>{translateActions('delete')}</ContextualButton>

              <ContextualButton onClick={onCancelDeleteClick}>{translateActions('cancel')}</ContextualButton>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default ExperienceCourseSteps
