import { type UseMutateAsyncFunction } from '@tanstack/react-query'
import { type ReactNode } from 'react'

import { type Field } from '@interfaces/api/definition/field'
import { type Resource } from '@services/api/definition/resource'

export enum ApiResourceFormPropsMethod {
  PATCH = 'patch',
  POST = 'post'
}
export interface ApiResourceFormProps<T> {
  children?: ReactNode
  createMode?: boolean
  customAttributes?: string[]
  data?: T
  definition: Resource
  disableNotification?: boolean
  excludedFields?: string[]
  extraFields?: Field[]
  extraValues?: Record<string, string>
  fieldsToDisplay?: string[]
  fieldsToHide?: string[],
  isModal?: boolean,
  isFetching?: boolean
  method: ApiResourceFormPropsMethod
  requestFields: Field[]
  submitEntry: UseMutateAsyncFunction<unknown, Error, unknown, unknown>
}
