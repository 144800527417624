import { Dialog, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  BuildingLibraryIcon,
  BuildingOffice2Icon, CheckIcon,
  ChevronRightIcon,
  DocumentIcon,
  FolderIcon,
  MagnifyingGlassIcon,
  MapIcon,
  PhotoIcon,
  ShoppingCartIcon,
  StarIcon,
  TrophyIcon,
  UserIcon,
  VideoCameraIcon
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ContextualButton from '@components/buttons/contextual-button'
import { type GlobalSearchInterfaces } from '@components/global-search/global-search.interfaces'
import useMeiliSearchMultiQuery from '@services/api/resources/meili-multi-query'

const GlobalSearchIcon = {
  activity: TrophyIcon,
  artist: UserIcon,
  artwork: PhotoIcon,
  attraction: StarIcon,
  city: BuildingLibraryIcon,
  course: MapIcon,
  hotel: BuildingOffice2Icon,
  page: DocumentIcon,
  product: ShoppingCartIcon,
  video: VideoCameraIcon
}

const GlobalSearch = ({
  emptyStateButtonLabel,
  emptyStateOnClick,
  excludedUids = [],
  indexes,
  isOpened,
  multiple = false,
  onSelectCallback,
  setOpened
}: GlobalSearchInterfaces) => {
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState('')
  const [selectedIndices, setSelectedIndices] = useState<number[]>([])
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [selectedResults, setSelectedResults] = useState<any[]>([])
  const { t: translateResource } = useTranslation('general', { keyPrefix: 'search' })
  const navigate = useNavigate()

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault()
        if (!setOpened) {
          setOpen(true)
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const closeModal = () => {
    setOpen(false)
    if (setOpened) {
      setOpened(false)
    }
  }

  const environmentPrefix = process.env.NODE_ENV === 'production' ? 'app_' : 'app_dev_'
  const adjustedIndexes = indexes.map((index) => environmentPrefix + index)

  const { data = [] } = useMeiliSearchMultiQuery({
    enabled: query !== '',
    queries: adjustedIndexes.map((index) => ({
      indexUid: index,
      limit: 30,
      q: query,
      showRankingScore: true
    }))
  })

  const mergeHitsWithType = (data) => {
    const mergedData = data.flatMap((item) =>
      item.hits
        .filter((hit) => !excludedUids.includes(hit.uid))
        .map((hit) => {
          const name = hit.name || hit.fullName || hit.label || hit.title || ''

          return {
            ...hit,
            name,
            type: item.indexUid.replace(environmentPrefix, '')
          }
        })
    )
    mergedData.sort((a, b) => b._rankingScore - a._rankingScore)

    return mergedData
  }

  const mergedHits = mergeHitsWithType(data)

  const navigateToHit = (hit) => {
    let path
    switch (hit.type) {
      case 'city':
        path = `/cities/${hit.uid}`
        break
      case 'artist':
        path = `/artists/${hit.uid}`
        break
      case 'attraction':
        path = `/experiences/attraction/${hit.uid}`
        break
      case 'activity':
        path = `/experiences/activity/${hit.uid}`
        break
      case 'artwork':
        path = `/experiences/artwork/${hit.uid}`
        break
      case 'course':
        path = `/experiences/course/${hit.uid}`
        break
      case 'hotel':
        path = `/experiences/hotel/${hit.uid}`
        break
      case 'video':
        path = `/videos/${hit.uid}`
        break
      default:
        path = '/'
    }
    setOpen(false)
    navigate(path)
  }

  const onSelect = (hit, index) => {
    if (multiple) {
      if (selectedIndices.includes(index)) {
        setSelectedIndices(selectedIndices.filter((i) => i !== index))
        setSelectedResults(selectedResults.filter((result) => result.uid !== hit.uid))
      } else {
        setSelectedIndices([...selectedIndices, index])
        setSelectedResults([...selectedResults, hit])
      }
    } else {
      if (onSelectCallback) {
        onSelectCallback(hit)
        setOpen(false)
        if (setOpened) {
          setOpened(false)
        }
      } else {
        navigateToHit(hit)
      }
    }
  }

  const handleSelectMultiple = () => {
    if (multiple && onSelectCallback) {
      onSelectCallback(selectedResults)
      setOpen(false)
      if (setOpened) {
        setOpened(false)
      }
    }
  }

  const handleKeyDownInInput = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      setActiveIndex((prevIndex) => {
        // Si on est au dernier élément, rester au dernier index
        return Math.min(prevIndex + 1, mergedHits.length - 1)
      })
    } else if (event.key === 'ArrowUp') {
      event.preventDefault()
      setActiveIndex((prevIndex) => {
        // Si on est au premier élément, rester au premier index
        return Math.max(prevIndex - 1, 0)
      })
    } else if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      event.preventDefault()
      // Si cmd + Enter ou ctrl + Enter pressé, gérer la sélection multiple
      handleSelectMultiple()
    } else if (event.key === 'Enter' && mergedHits[activeIndex]) {
      onSelect(mergedHits[activeIndex], activeIndex)
    }
  }

  const renderRow = (active, entry, selected) => {
    const Icon = GlobalSearchIcon[entry.type]
    const city = entry.cities && entry.cities.length > 0 ? entry.cities[0] : entry.city

    let label = entry.name

    if (city) {
      label = `${city.name} - ${entry.name}`
    }

    return (
      <div
        className={classNames(
          'flex items-center p-3 rounded-md',
          {
            'bg-gray-700': selected && active,
            'bg-gray-700 text-white': selected && !active,
            'text-gray-400': !active && !selected,
            'text-primary': (selected && active) || (active && !selected)
          }
        )}
      >
        <Icon aria-hidden='true' className='h-6 w-6 flex-none' />

        <span className='ml-3 flex-auto truncate'>{label}</span>

        {active && !selected && <ChevronRightIcon className='ml-3 flex-none text-gray-400 h-6 w-6' />}

        {selected && <CheckIcon className='ml-3 flex-none text-gray-400 h-6 w-6' />}
      </div>
    )
  }

  return (
    <Transition.Root
      afterLeave={() => {
        setQuery('')
        setSelectedResults([])
        setSelectedIndices([])
        setActiveIndex(0)
      }}
      appear as={Fragment}
      show={open || isOpened === true}
    >
      <Dialog as='div' className='relative z-99' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-99 overflow-y-auto p-4 sm:p-6 md:p-20'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Dialog.Panel className='mx-auto max-w-4xl transform divide-y divide-gray-500 divide-opacity-20 overflow-hidden rounded-xl bg-gray-900 shadow-2xl transition-all'>

              <div className='flex items-center space-x-2'>
                <MagnifyingGlassIcon aria-hidden='true' className='ml-4 size-5 text-gray-500' />

                <input
                  className='w-full bg-transparent pr-4 h-12 text-white sm:text-sm border-transparent outline-none'
                  onChange={(event) => {
                    setQuery(event.target.value)
                  }}
                  onKeyDown={handleKeyDownInInput}
                  placeholder='Rechercher...'
                  type='text'
                  value={query}
                />
              </div>

              {query === '' || mergedHits.length > 0
                ? (
                  <div className='max-h-[calc(100vh-5rem)] sm:max-h-[calc(100vh-6rem)] md:max-h-[calc(100vh-13rem)] scroll-py-2 overflow-y-auto'>
                    <ul className='divide-y divide-gray-600'>
                      {mergedHits.map((entry, index) => (
                        <li
                          className={classNames(
                            'cursor-pointer'
                          )}
                          key={entry.uid}
                          onClick={() => {
                            onSelect(entry, index)
                          }}
                          tabIndex={0}
                        >
                          {renderRow(activeIndex === index, entry, selectedIndices.includes(index))}
                        </li>
                      ))}
                    </ul>
                  </div>
                )
                : (
                  <div className='px-6 py-14 text-center sm:px-14 flex flex-col items-center justify-center space-y-4'>
                    <FolderIcon aria-hidden='true' className='mx-auto h-6 w-6 text-gray-500' />

                    <p className='text-sm text-gray-200'>{translateResource('empty')}</p>

                    {emptyStateOnClick && emptyStateButtonLabel && (
                      <ContextualButton
                        icon={ArrowPathIcon}
                        onClick={() => {
                          emptyStateOnClick(query)
                          setOpen(false)
                        }}
                      >
                        {emptyStateButtonLabel}
                      </ContextualButton>
                    )}
                  </div>
                )}

              {onSelectCallback && multiple && selectedResults.length > 0 && (
                <div className='px-4 py-2'>
                  <ContextualButton onClick={handleSelectMultiple}>
                    {translateResource('selectMultiple', { count: selectedResults.length })}
                  </ContextualButton>
                </div>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default GlobalSearch
