import { type ApiPlatformEntity, type ApiPlatformListResponse } from '@interfaces/api'
import { type City } from '@interfaces/api/city/city'
import { type Page } from '@interfaces/api/page'

export interface CityPage extends ApiPlatformEntity {
  city: City
  page: Page
  priority: number
  slug: string
  type: CityPageType
  uid: string
}

export enum CityPageType {
  ACTIVITIES = 'activities',
  AUDIO_GUIDES = 'audio_guides',
  HOTELS = 'hotels',
  OUR_SELECTIONS = 'our_selections',
  RESTAURANTS = 'restaurants',
  SECTIONS = 'sections',
  TABS = 'tabs',
  TOURS = 'tours'
}

export enum CityPageMenus {
  HOME_MOBILE = 'HOME_MOBILE',
  SECTIONS = 'SECTIONS',
  TAB_BAR = 'TAB_BAR',
  TABS = 'TABS'
}

export const CityPageHomeMobile = [
  CityPageType.AUDIO_GUIDES,
  CityPageType.HOTELS,
  CityPageType.RESTAURANTS,
  CityPageType.OUR_SELECTIONS
]

export type CityPagesResponse = ApiPlatformListResponse<CityPage>
