import { ArrowLeftIcon, ArrowRightIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResourceCreate from '@components/api-resource/api-resource-create'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import ContextualButton from '@components/buttons/contextual-button'
import IconButton from '@components/buttons/icon-button'
import ExperienceCourseTabSteps from '@components/experiences/experience-course-tabs/experience-course-tab-steps'
import Modal from '@components/modals/modal'
import type { ApiPlatformEntity } from '@interfaces/api'
import { type Course, type CourseTab } from '@interfaces/api/course/course'
import useApiResource from '@services/api/definition'
import useDeleteEntrySimple from '@services/api/resources/delete-entry-query-simple'
import useItemChildrenQuery from '@services/api/resources/item-children'
import usePatchEntrySimple from '@services/api/resources/patch-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'

interface ExperienceCourseTabsProps {
  course: Course
}

interface CourseTabInput extends ApiPlatformEntity {
  course: string
}

const ExperienceCourseTabs = ({ course }: ExperienceCourseTabsProps) => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'course-tabs' })
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })

  const [currentTab, setCurrentTab] = useState<CourseTab>()
  const [tabsEditMode, setTabsEditMode] = useState(false)
  const [openCreateTab, setOpenCreateTab] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)

  const definition = useApiResource('course-tabs/{uid}')
  const collectionDefinition = useApiResource('course-tabs')
  const { mutateAsync: patchResourceEntry } = usePatchEntrySimple()
  const { mutateAsync: deleteResourceEntry } = useDeleteEntrySimple()

  const newCourseTab: CourseTabInput = {
    '@id': '',
    '@type': '',
    course: course['@id'],
    uid: ''
  }

  const {
    data: {
      data: courseTabs
    } = {},
    refetch: refetchTabs
  } = useItemChildrenQuery<CourseTab>({
    itemId: course.uid,
    path: 'courses/{uid}/tabs'
  })

  useEffect(() => {
    if (!currentTab && courseTabs && courseTabs.length > 0) {
      setCurrentTab(courseTabs[0])
    }
  }, [courseTabs])

  const addNewTab = () => {
    setOpenCreateTab(true)
  }

  const onTabSelect = (tab: CourseTab) => {
    setCurrentTab(tab)
  }

  const onTabCreateSuccess = () => {
    setOpenCreateTab(false)
    refetchTabs().catch(captureException)
  }

  const onTabEditSuccess = () => {
    refetchTabs().catch(captureException)
  }

  const tabMove = (tab: CourseTab, direction) => {
    let currentPosition = tab.priority

    if (direction === 'right') {
      currentPosition = currentPosition + 1
    } else if (direction === 'left') {
      currentPosition = currentPosition - 1
    }

    patchResourceEntry({ data: { priority: currentPosition }, id: tab.uid, path: 'course-tabs/{uid}' }).then(() => {
      refetchTabs().catch(captureException)
    }).catch(captureException)
  }

  const onDeleteTab = () => {
    setOpenDeleteModal(true)
  }

  const onDeleteHandler = () => {
    if (currentTab && courseTabs) {
      const currentIndex = courseTabs.findIndex((tab) => tab.uid === currentTab.uid)
      let previousTab
      if (courseTabs.length > 1) {
        if (currentIndex > 0) {
          previousTab = courseTabs[currentIndex - 1]
        } else {
          previousTab = courseTabs[1]
        }
      }

      deleteResourceEntry({ id: currentTab.uid, path: 'course-tabs/{uid}' }).then(() => {
        refetchTabs().catch(captureException)
        setOpenDeleteModal(false)
        setCurrentTab(previousTab ?? undefined)
      }).catch(captureException)
    }
  }

  const handleCancelClick = () => {
    setOpenDeleteModal(false)
  }

  return (
    <>
      <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-10'>
        <div className='px-4 py-4'>
          <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
            <div className='ml-4 mt-4'>
              <h3 className='text-xl font-semibold leading-6 text-slate-700'>Tabs parcours</h3>
            </div>

            <div className='ml-4 mt-4 flex-shrink-0'>

              <ContextualButton
                onClick={() => {
                  setTabsEditMode(!tabsEditMode)
                }}
                style={tabsEditMode ? 'success' : undefined}
              >
                {tabsEditMode ? 'Terminer' : 'Modifier les tabs'}
              </ContextualButton>
            </div>
          </div>
        </div>

        <nav aria-label='Tabs' className='flex space-x-4 mb-4 mx-2'>
          {courseTabs?.map((tab) => (
            <>
              {tab.uid === currentTab?.uid && tabsEditMode && (
                <IconButton
                  icon={ArrowLeftIcon}

                  onClick={() => {
                    tabMove(tab, 'left')
                  }}
                />
              )}
              <button
                className={classNames(
                  tab.uid === currentTab?.uid ? 'bg-gray-900 text-white' : 'text-gray-500 hover:text-gray-700',
                  'rounded-md px-3 py-2 text-sm font-medium flex items-center space-x-2'
                )}
                key={tab.uid}
                onClick={() => {
                  if (tab.uid === currentTab?.uid && tabsEditMode) {
                    onDeleteTab()
                  } else {
                    onTabSelect(tab)
                  }
                }}
                type={'button'}
              >
                <span>{tab.name}</span>

                {tab.uid === currentTab?.uid && tabsEditMode && (
                  <TrashIcon className='size-4' />
                )}
              </button>
              {tab.uid === currentTab?.uid && tabsEditMode && (
                <IconButton
                  icon={ArrowRightIcon}

                  onClick={() => {
                    tabMove(tab, 'right')
                  }}
                />
              )}
            </>
          ))}

          <button
            className='text-black rounded-md px-2 text-sm font-medium hover:text-white hover:bg-black'
            onClick={addNewTab}
            type={'button'}
          >
            <PlusIcon className='size-5' />
          </button>
        </nav>

        {tabsEditMode
          ? (
            <>
              {definition && currentTab &&
                <ApiResourceEdit definition={definition} fieldsToHide={['priority']} id={currentTab?.uid} onEdit={onTabEditSuccess} />}
            </>
          )
          : (
            <>
              {currentTab && (
                <ExperienceCourseTabSteps courseTab={currentTab} />
              )}
            </>
          )}

      </div>

      <Modal center open={openCreateTab} setOpen={setOpenCreateTab} size='large' title={translateResource('create')}>
        {collectionDefinition && <ApiResourceCreate data={newCourseTab} definition={collectionDefinition} fieldsToHide={['priority']} isModal onEdit={onTabCreateSuccess} />}
      </Modal>

      <Modal center open={openDeleteModal} setOpen={setOpenDeleteModal} title={translateActions('deleteResource')}>
        <div className='flex flex-col items-center p-8'>
          <div className='text-center text-xl'>{translateActions('deleteConfirmation')}</div>

          <div className='flex mt-6 gap-8'>
            <ContextualButton onClick={onDeleteHandler} style='warning'>{translateActions('delete')}</ContextualButton>

            <ContextualButton onClick={handleCancelClick}>{translateActions('cancel')}</ContextualButton>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ExperienceCourseTabs
