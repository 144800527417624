import { CameraIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import ApiResource from '@components/api-resource'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import ModalVideoPlayer from '@components/modals/modal-video-player'
import SectionTitle from '@components/section-title/section-title'
import BatchUploader from '@components/video/batch-uploader'
import { type ApiPlatformEntity } from '@interfaces/api/api'
import { type Video } from '@interfaces/api/video'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'

const VideosList = () => {
  const { t } = useTranslation('videos')
  const { t: bc } = useTranslation('breadcrumbs')
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'videos' })
  const { id = '' } = useParams()

  // Opens a preview when clicking on a video entry
  const [video, setVideo] = useState<Video>()
  const [isOpen, setIsOpen] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const definition = useApiResource('videos/{uid}')
  const queryClient = useQueryClient()

  const breadcrumbs = [
    { href: '', name: bc('videos') }
  ]

  useEffect(() => {
    if (id !== '') {
      setEditModal(true)
    }
  }, [id])

  const onEntryClick = (entry: ApiPlatformEntity) => {
    setIsOpen(true)
    setVideo(entry as Video)
  }

  const onEditClick = (entry: ApiPlatformEntity) => {
    setVideo(entry as Video)
    setEditModal(true)
  }

  const onEdit = () => {
    onReload()
    setEditModal(false)
  }

  const onReload = () => {
    queryClient.refetchQueries({ queryKey: ['videos'] }).catch(captureException)
  }

  return (
    <Layout description={t('description')} title={t('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={CameraIcon} title={t('title')} />

        <BatchUploader />

        <ApiResource createdAt={'desc'} customFieldsToDisplay={['status', 'label', 'duration', 'createdAt']} name={'videos'} onClick={onEntryClick} withDeleteOption withEditOption={onEditClick} />

        <>
          {video && (
            <ModalVideoPlayer
              open={isOpen}
              setOpen={setIsOpen}
              video={video}
            />
          )}
        </>

        <>
          {(video ?? id) && (
            <Modal center open={editModal} setOpen={setEditModal} size='large' title={translateResource('edit')}>
              {definition && <ApiResourceEdit definition={definition} id={video ? video.uid : id} isModal onEdit={onEdit} />}
            </Modal>
          )}
        </>
      </Guard>
    </Layout>
  )
}

export default VideosList
