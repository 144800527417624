import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'

import { type AutocompleteEntityInterfaces } from '@components/autocomplete-entity/autocomplete-entity.interfaces'

const AutocompleteEntity = <T,>({
  displayKey,
  emptyStateLabel,
  items,
  label,
  onChange,
  placeholder,
  query,
  required = false,
  selectedOption,
  setQuery
}: AutocompleteEntityInterfaces<T>) => {
  const filteredItems = query
    ? items.filter((item) =>
      String(item[displayKey]).toLowerCase().includes(query.toLowerCase())
    )
    : items

  return (
    <div className='flex flex-col w-full'>
      <Combobox as='div' nullable onChange={onChange} value={selectedOption}>
        <Combobox.Label className='block font-medium text-gray-700 mb-2'>{label}</Combobox.Label>

        <div className='relative mt-2'>
          <Combobox.Button className='w-full'>
            <Combobox.Input
              className='appearance-none w-full rounded-md bg-gray-50 border border-gray-300 py-1.5 pl-3 pr-10 text-gray-900 shadow-sm sm:text-sm sm:leading-6 focus:outline-none'
              displayValue={(item: T | null) => (item ? String(item[displayKey]) : '')}
              onChange={(event) => {
                setQuery(event.target.value)
              }}
              placeholder={placeholder ?? 'Select an option'}
            />

            <span className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
              <ChevronUpDownIcon aria-hidden='true' className='h-5 w-5 text-gray-400' />
            </span>
          </Combobox.Button>

          {filteredItems.length > 0 && (
            <Combobox.Options className='absolute z-10 w-full mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm'>
              {!required && (
                <Combobox.Option
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-slate-900 text-white' : 'text-gray-900'
                    )
                  }
                  key={'null-item'}
                  value={null}
                >
                  {({ active }) => (
                    <span className={classNames('block truncate', active && 'font-semibold')}>
                      {emptyStateLabel ?? '- Select -'}
                    </span>
                  )}
                </Combobox.Option>
              )}

              {filteredItems.map((item, index) => (
                <Combobox.Option
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-slate-900 text-white' : 'text-gray-900'
                    )
                  }
                  key={index}
                  value={item}
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames('block truncate', selected && 'font-semibold')}
                      >
                        {String(item[displayKey])}
                      </span>
                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-slate-900'
                          )}
                        >
                          <CheckIcon aria-hidden='true' className='h-5 w-5' />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </div>
  )
}

export default AutocompleteEntity
