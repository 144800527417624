import { ShoppingBagIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ApiResource from '@components/api-resource'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import SectionTitle from '@components/section-title/section-title'

const OrdersPartnerList = () => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'partner-orders' })
  const navigate = useNavigate()
  const breadcrumbs = [
    { href: '/orders-partner', name: translateResource('title') }
  ]

  const onEntryClick = (entry) => {
    navigate(`/orders-partner/${entry.uid}`, { state: { breadcrumbs } })
  }

  return (
    <Layout description={translateResource('description')} title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={ShoppingBagIcon} title={translateResource('title')} />

        <ApiResource
          createdAt={'desc'}
          customFieldsToDisplay={['partner', 'state', 'totalPrice', 'paidAt', 'createdAt']}
          customFilters={['partner', 'state']}
          name={'partner-orders'}
          onClick={onEntryClick}
        />
      </Guard>
    </Layout>
  )
}

export default OrdersPartnerList
