import { ShoppingBagIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ApiResource from '@components/api-resource'
import BookingListEntry from '@components/bookings/booking-list-entry/booking-list-entry'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import SectionTitle from '@components/section-title/section-title'
import { BookingShippingState } from '@interfaces/api/booking/booking'

const Home = () => {
  const { t: translateGeneral } = useTranslation('general')
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'bookings-awaiting-shipping' })
  const navigate = useNavigate()
  const onBookingClick = (entry) => {
    navigate(`/bookings/${entry.uid}`)
  }

  return (
    <Layout title={translateGeneral('home')}>
      <Breadcrumbs />

      <Guard errorPage={false}>
        <SectionTitle icon={ShoppingBagIcon} title={translateResource('title')} />

        <ApiResource
          createdAt={'desc'}
          customFieldsToDisplay={['orderNumber', 'customerLastName', 'customerLastName', 'creationMethod', 'state', 'shippingState', 'totalPrice', 'startTimeLocal']}
          customFilters={['orderNumber', 'state', 'user']}
          customParams={[{ 'order[startTimeLocal]': 'desc', shippingState: BookingShippingState.awaiting_manual_shipment }]}
          entryComponent={BookingListEntry}
          name={'bookings'}
          onClick={onBookingClick}
        />
      </Guard>
    </Layout>
  )
}

export default Home
