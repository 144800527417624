/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { PencilSquareIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import { type UseMutationResult } from '@tanstack/react-query'
import classNames from 'classnames'
import { Form, Formik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResourceFormObserver from '@components/api-resource/api-resource-form/api-resource-form-observer'
import { BooleanFields } from '@components/api-resource/api-resource-form/form-fields/boolean-fields'
import { CustomAttributesFields } from '@components/api-resource/api-resource-form/form-fields/custom-attributes-fields'
import { DefaultFields } from '@components/api-resource/api-resource-form/form-fields/default-fields'
import { GroupedFields } from '@components/api-resource/api-resource-form/form-fields/grouped-fields'
import { MapFields } from '@components/api-resource/api-resource-form/form-fields/map-fields'
import { MediaFields } from '@components/api-resource/api-resource-form/form-fields/media-fields'
import { NumberFields } from '@components/api-resource/api-resource-form/form-fields/number-fields'
import { RadioFields } from '@components/api-resource/api-resource-form/form-fields/radio-fields'
import { RelationFields } from '@components/api-resource/api-resource-form/form-fields/relation-fields'
import { SelectFields } from '@components/api-resource/api-resource-form/form-fields/select-fields'
import { StatusFields } from '@components/api-resource/api-resource-form/form-fields/status-fields'
import { TagsFields } from '@components/api-resource/api-resource-form/form-fields/tags-fields'
import { TranslatedFields } from '@components/api-resource/api-resource-form/form-fields/translated-fields'
import {
  FILE_FIELDS, hasMediaOrImageFields,
  IMAGE_FIELDS,
  IMAGE_MULTI_FIELDS
} from '@components/api-resource/api-resource-form/form-utils/fields'
import {
  getFlatTranslatedData,
  getTranslatedDataFieldAttributes,
  getTranslatedDataFieldAttributesProperties
} from '@components/api-resource/api-resource-form/form-utils/translated-data'
import { type ApiResourceFormProps } from '@components/api-resource/api-resource-form/index'
import ContextualButton from '@components/buttons/contextual-button'
import { NotificationType } from '@components/notification/notification.interfaces'
import SkeletonLoader from '@components/skeleton-loader/skeleton-loader'
import { type ApiPlatformEntity, type ApiReponseError } from '@interfaces/api/api'
import { usePostFileQuery } from '@services/api/resources/post-entry-file'
import { useUploadImage } from '@services/api/upload-image/upload-image'
import { captureException } from '@services/exceptions/capture-exception'
import { useFormStore } from '@services/stores/forms/forms'
import { useNotificationStore } from '@services/stores/notification/notification'
import { useScheduleInputStore } from '@services/stores/schedule-input/schedule-input'
import { getInitialValues } from '@services/tools/api-resources/formik'
import { handleImageViolations } from '@services/tools/api-resources/violations'
import { populateCustomData } from '@services/tools/custom-data'

const parseData = (data, customAttributes, extraValues) => {
  if (data && 'customData' in data && data.customData === null && customAttributes) {
    data.customData = populateCustomData(customAttributes)
  }

  if (extraValues) {
    data = { ...data, ...extraValues }
  }

  return data
}

const ApiResourceForm = <T extends ApiPlatformEntity>({ children, createMode = false, customAttributes, data, definition, disableNotification, excludedFields, extraFields, extraValues, fieldsToDisplay, fieldsToHide, isFetching, isModal = false, method, requestFields, submitEntry }: ApiResourceFormProps<T>) => {
  const { t } = useTranslation('apiResources')
  const { t: translateError } = useTranslation('errors')
  const [formError, setFormError] = useState('')

  const [isUploading, setIsUploading] = useState(false)

  const { inputIsInvalid } = useScheduleInputStore()
  const { displayNotification } = useNotificationStore()

  const { mutateAsync: uploadImageAsync } = useUploadImage()
  const { mutateAsync: uploadFileAsync } = usePostFileQuery('files')

  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    const newInitialValues = getInitialValues<T>(requestFields, parseData(data, customAttributes, extraValues) ?? [], extraFields)
    setInitialValues(newInitialValues)
  }, [data])

  const formValues = useFormStore((state) => state.forms[definition.name]) ?? initialValues

  const translatedDataFieldAttributes = useMemo(() => getTranslatedDataFieldAttributes(
    requestFields, definition, method, t, fieldsToHide
  ), [requestFields, definition, method, t, fieldsToHide])

  const flatTranslatedData = useMemo(() => getFlatTranslatedData(requestFields, definition, method),
    [requestFields, definition, method]
  )
  const handleError = (err, setErrors) => {
    const error = err as UseMutationResult<ApiReponseError>
    const errorDetails = error.data?.detail

    captureException(err as Error)

    if (errorDetails) {
      const [errorKey, errorMessage] = errorDetails.split(':').map(part => part.trim())

      if (errorKey && errorMessage) {
        const translation = translateError(`apiErrors.${errorKey}.${errorMessage}`, { defaultValue: errorDetails })

        if (translation === errorDetails) {
          const missingTranslationError = new Error(`Missing translation for error code: ${errorDetails}`)
          captureException(missingTranslationError)
          setErrors({ [errorKey]: errorMessage })
        } else {
          setErrors({ [errorKey]: translation })
        }
      } else {
        setFormError(`Erreur système : ${error.data?.detail}`)
      }
    }
  }

  const onSubmit = async (values, { setErrors, setSubmitting }) => {
    setSubmitting(true)

    let errorMessage = ''

    for (const key in values) {
      const field = requestFields.find(field => field.name === key)
      const fieldIsTranslatedData = field?.name.toLowerCase().includes('translateddata') ?? false

      if (fieldIsTranslatedData && field) {
        const translatedData = {}

        getTranslatedDataFieldAttributesProperties(field?.name, requestFields, definition, method)
          .forEach((fieldName) => {
            if (fieldName in values) {
              translatedData[fieldName] = values[fieldName]
            }
          })
      }

      if (values.video && key === 'video' && typeof values.video === 'object') {
        values[key] = values.video['@id']
      }

      if (!values.city && field && field.name === 'city' && field.required) {
        errorMessage = 'La ville est requise'
        setErrors({ city: errorMessage })
      }

      if (values.validated && values.tariffGrid === '') {
        errorMessage = 'La grille tarifaire doit être définie si vous souhaitez valider le compte'
        setErrors({ tariffGrid: errorMessage })
      }

      if (values.attractionType === '') {
        values.attractionType = null
      }

      if (values.introVideo && key === 'introVideo' && typeof values.introVideo === 'object') {
        values[key] = values.introVideo['@id']
      }

      if (IMAGE_FIELDS.includes(key) && values[key] && typeof values[key] !== 'string') {
        if (typeof values[key] === 'object' && '@id' in values[key]) {
          values[key] = values[key]['@id']
        } else {
          const formData = new FormData()
          formData.append('file', values[key])
          try {
            values[key] = await uploadImageAsync(formData)
          } catch (e) {
            const err = e as UseMutationResult<ApiReponseError>
            errorMessage = handleImageViolations(err?.data?.violations ?? [])
            setErrors({ [key]: errorMessage })
          }
        }
      } else if (FILE_FIELDS.includes(key) && values[key] && typeof values[key] !== 'string') {
        if (typeof values[key] === 'object' && '@id' in values[key]) {
          values[key] = values[key]['@id']
        } else {
          const formData = new FormData()
          formData.append('file', values[key])
          try {
            values[key] = await uploadFileAsync(formData)
          } catch (e) {
            const err = e as UseMutationResult<ApiReponseError>
            errorMessage = handleImageViolations(err?.data?.violations ?? [])
            setErrors({ [key]: errorMessage })
          }
        }
      } else if (IMAGE_MULTI_FIELDS.includes(key) && values[key] && Array.isArray(values[key])) {
        const uploadedImagesIds: string[] = []
        for (const file of values[key]) {
          if (typeof file === 'object' && '@id' in file) {
            uploadedImagesIds.push(file['@id'])
          } else {
            const formData = new FormData()
            formData.append('file', file)
            try {
              const uploadedId = await uploadImageAsync(formData)
              uploadedImagesIds.push(uploadedId)
            } catch (e) {
              const err = e as UseMutationResult<ApiReponseError>
              errorMessage = handleImageViolations(err?.data?.violations ?? [])
              setErrors({ [key]: errorMessage })
              break // Stop processing further if any error occurs
            }
          }
        }
        if (!errorMessage) {
          values[key] = uploadedImagesIds
        }
      }
    }

    if (!errorMessage) {
      try {
        const submittedValues = Object.assign({}, values)

        for (const key in submittedValues) {
          const field = requestFields.find(field => field.name === key)

          // convert object to string values for iri
          if (typeof submittedValues[key] === 'object' && submittedValues[key] !== null && field && 'type' in field.properties && field.properties.format === 'iri-reference') {
            submittedValues[key] = submittedValues[key]['@id']
          }

          // empty values for iri
          if (
            submittedValues[key] === '' &&
            field &&
            (('format' in field.properties && field.properties.format === 'iri-reference'))
          ) {
            submittedValues[key] = null
          }

          if (field && field.name === 'duration' && submittedValues[key] === '') {
            submittedValues[key] = null
          }

          if (field && field.name === 'price' && submittedValues[key]) {
            submittedValues[key] = submittedValues[key] * 100
          }
        }

        const result = await submitEntry(submittedValues)
        setSubmitting(false)
        setInitialValues(getInitialValues<T>(requestFields, parseData(result, customAttributes, extraValues) ?? []))
        !disableNotification && displayNotification(t(`${definition.name}.message.${createMode ? 'create' : 'update'}.success.title`), t(`${definition.name}.message.${createMode ? 'create' : 'update'}.success.description`), NotificationType.success)
      } catch (err) {
        handleError(err, setErrors)
      }
    } else {
      setSubmitting(false)
    }
  }

  if (isFetching) {
    return (
      <div className='py-3 px-4 w-full'>
        <SkeletonLoader type='form' />
      </div>
    )
  }

  return (
    <div className='py-3 px-4 w-full max-h-full'>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, setFieldValue }) => {
          return (
            <Form className='space-y-6'>
              <ApiResourceFormObserver definitionName={definition.name} />

              <div className='grid grid-cols-2 gap-10'>
                <div className='flex flex-col space-y-4'>
                  <DefaultFields
                    data={data}
                    definition={definition}
                    fieldsToDisplay={fieldsToDisplay}
                    fieldsToHide={fieldsToHide}
                    flatTranslatedData={flatTranslatedData}
                    requestFields={requestFields}
                  />

                  {children}

                  <div className='grid grid-cols-2 gap-4'>
                    <GroupedFields
                      data={data}
                      definition={definition}
                      fieldsToDisplay={fieldsToDisplay}
                      fieldsToHide={fieldsToHide}
                      requestFields={requestFields}
                    />
                  </div>

                  <div className='grid grid-cols-2 gap-4'>
                    <SelectFields
                      data={data}
                      definition={definition}
                      fieldsToDisplay={fieldsToDisplay}
                      fieldsToHide={fieldsToHide}
                      formValues={formValues}
                      requestFields={requestFields}
                    />

                    <NumberFields
                      createMode={createMode}
                      definition={definition}
                      fieldsToDisplay={fieldsToDisplay}
                      fieldsToHide={fieldsToHide}
                      requestFields={requestFields}
                    />
                  </div>

                  <div className='grid grid-cols-2 gap-4'>
                    <RelationFields
                      data={data}
                      definition={definition}
                      excludedFields={excludedFields}
                      fieldsToDisplay={fieldsToDisplay}
                      fieldsToHide={fieldsToHide}
                      formValues={formValues}
                      requestFields={requestFields}
                    />
                  </div>

                  <div>
                    <MapFields
                      definition={definition}
                      fieldsToDisplay={fieldsToDisplay}
                      fieldsToHide={fieldsToHide}
                      formValues={formValues}
                      requestFields={requestFields}
                    />
                  </div>
                </div>

                <div className='flex flex-col items-end pr-10'>
                  <div className='flex flex-col gap-4'>
                    <StatusFields
                      definition={definition}
                      requestFields={requestFields}
                    />

                    <BooleanFields
                      definition={definition}
                      fieldsToDisplay={fieldsToDisplay}
                      fieldsToHide={fieldsToHide}
                      requestFields={requestFields}
                    />

                    <CustomAttributesFields
                      customAttributes={customAttributes}
                    />

                    <TagsFields
                      data={data}
                      definition={definition}
                      excludedFields={excludedFields}
                      fieldsToDisplay={fieldsToDisplay}
                      fieldsToHide={fieldsToHide}
                      formValues={formValues}
                      requestFields={requestFields}
                    />
                  </div>
                </div>
              </div>

              <div>
                <RadioFields
                  definition={definition}
                  fieldsToDisplay={fieldsToDisplay}
                  fieldsToHide={fieldsToHide}
                  flatTranslatedData={flatTranslatedData}
                  requestFields={requestFields}
                />
              </div>

              <div className='flex gap-4'>
                <div className='flex flex-col gap-3 w-full'>

                  {hasMediaOrImageFields(requestFields) && (
                    <MediaFields
                      definition={definition}
                      initialValues={initialValues}
                      isUploading={isUploading}
                      requestFields={requestFields}
                      setIsUploading={setIsUploading}
                    />
                  )}

                  <TranslatedFields
                    initialValues={initialValues}
                    translatedDataFieldAttributes={translatedDataFieldAttributes}
                  />
                </div>
              </div>

              {formError && (
                <div className='mt-2 text-xs text-red-600 font-medium'>
                  {formError}
                </div>
              )}

              <div className={classNames(
                'w-full bottom-0 right-0 py-2 pr-4 flex items-end justify-end',
                {
                  'fixed bg-white/10 backdrop-blur-lg': !isModal,
                  'sticky bg-white/10 backdrop-blur-lg': isModal
                }
              )}
              >
                <ContextualButton
                  disabled={isSubmitting || isUploading || inputIsInvalid}
                  icon={createMode ? PlusCircleIcon : PencilSquareIcon}
                  isLoading={isSubmitting}
                  type='submit'
                >
                  {t(`buttons.${createMode ? 'create' : 'update'}`)}

                </ContextualButton>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default ApiResourceForm
