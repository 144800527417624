import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { ErrorMessage, Field, useField } from 'formik'
import { pluralize } from 'inflection'
import { useEffect, useState } from 'react'

import { type FormSearchFieldProps } from '@components/form-fields/form-search-field/form-search-field-interfaces'
import { type MeiliSearchArticle } from '@interfaces/api/meili/query'
import useMeiliSearchMultiQuery from '@services/api/resources/meili-multi-query'
import { captureException } from '@services/exceptions/capture-exception'

const FormSearchField = ({ className, emptyStateLabel, indexNames, label, name, required = false, value }: FormSearchFieldProps) => {
  const [field, , helpers] = useField({ name })
  const { setValue } = helpers
  const [query, setQuery] = useState('')
  const [selectedItem, setSelectedItem] = useState<MeiliSearchArticle>()
  const [isOpen, setIsOpen] = useState(false)

  const environmentPrefix = process.env.NODE_ENV === 'production' ? 'app_' : 'app_'
  const indexes = indexNames ?? [name]

  const adjustedIndexes = indexes.map((index) => environmentPrefix + index)

  const { data = [] } = useMeiliSearchMultiQuery({
    enabled: true,
    queries: adjustedIndexes.map((index) => ({
      indexUid: index,
      limit: 30,
      q: query,
      showRankingScore: true
    }))
  })

  const mergeHitsWithType = (data) => {
    const mergedData = data.flatMap((item) =>
      item.hits
        .map((hit) => {
          const name = hit.name || hit.fullName || hit.label || hit.title || ''

          return {
            ...hit,
            name,
            type: item.indexUid.replace(environmentPrefix, '')
          }
        })
    )
    mergedData.sort((a, b) => b._rankingScore - a._rankingScore)

    return mergedData
  }

  const mergedHits = mergeHitsWithType(data)

  const getApiPath = (uid) => {
    if (name === 'hrHotel') {
      return `/api/hotel-realm/hotels/${uid}`
    }

    return `/api/${pluralize(name)}/${uid}`
  }

  // On selected item update
  useEffect(() => {
    if (selectedItem?.uid) {
      // We set value like this because selectedItem come from meili and doesnt have @id
      setValue(getApiPath(selectedItem?.uid)).catch(captureException)
    } else if (!required) {
      setValue('').catch(captureException)
    }
  }, [selectedItem, setValue])

  // On init
  useEffect(() => {
    // Default value setup
    if (value && !selectedItem) {
      setSelectedItem(value)
    }
  }, [])

  useEffect(() => {
    if (!field.value && required && selectedItem) {
      setValue(getApiPath(selectedItem?.uid)).catch(captureException)
    }
  }, [field.value])

  // On init when items fetched
  useEffect(() => {
    // If no value and required we set first item as value
    if (required && !value && mergedHits[0] && !field.value) {
      setSelectedItem(mergedHits[0])
      // We set value like this because items[0] come from meili and doesnt have @id
      setValue(getApiPath(mergedHits[0]?.uid)).catch(captureException)
    }
  }, [mergedHits])

  // Use effect to handle data update when navigating
  useEffect(() => {
    if (selectedItem && value?.['@id'] && value?.['@id'] !== selectedItem['@id']) {
      setSelectedItem(value)
      setValue(value['@id']).catch(captureException)
    }
    if (value?.['@id'] && !selectedItem) {
      setSelectedItem(value)
    }
    if (!value && selectedItem) {
      setSelectedItem(undefined)
    }
  }, [value])

  const getDisplayName = (item) => {
    if (item === null) {
      return ''
    }

    return item.name?.en || item.name?.fr || item.name || item.fullName || item.label || item.title || ''
  }

  return (
    <div className={classNames('flex flex-col', {
      [`${className}`]: !!className
    })}
    >
      <Field name={name} required={required} type='hidden' />

      <Combobox as='div' nullable onChange={setSelectedItem} value={selectedItem}>
        <Combobox.Label className='block font-medium text-gray-700 text-sm'>
          {label}

          {required && <sup className='text-gray-500 font-normal'>*</sup>}
        </Combobox.Label>

        <div className='relative mt-2'>
          <Combobox.Button className='w-full'>
            <Combobox.Input
              className='appearance-none w-full rounded-md bg-gray-50 border border-gray-300 py-1.5 pl-3 pr-10 text-gray-900 shadow-sm sm:text-sm sm:leading-6 focus:outline-none'
              displayValue={(item) => getDisplayName(item)}
              onChange={(event) => {
                setQuery(event.target.value)
              }}
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            />

            <span className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
              <ChevronUpDownIcon aria-hidden='true' className='h-5 w-5 text-gray-400' />
            </span>
          </Combobox.Button>

          {mergedHits.length > 0 && (
            <Combobox.Options className='absolute z-10 w-full mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm'>
              {!required && (
                <Combobox.Option className={({ active }) => classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-slate-900 text-white' : 'text-gray-900')} value={''}>
                  {({ active, selected }) => (
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{emptyStateLabel ?? '- Select -'}</span>
                  )}
                </Combobox.Option>
              )}

              {mergedHits.map((item, index) => (
                <Combobox.Option className={({ active, selected }) => classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-slate-900 text-white' : 'text-gray-900', selected ? 'bg-primary text-white' : 'text-gray-900')} key={index} value={item}>
                  {({ active, selected }) => (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>{getDisplayName(item)}</span>
                      {selected && (
                        <span className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', active ? 'bg-slate-900 text-white' : 'text-primary', selected ? 'bg-primary text-white' : 'text-primary')}>
                          <CheckIcon aria-hidden='true' className='h-5 w-5' />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormSearchField
