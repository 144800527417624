import { PaintBrushIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ApiResource from '@components/api-resource'
import ApiResourceCreate from '@components/api-resource/api-resource-create'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import SectionTitle from '@components/section-title/section-title'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'

const ArtistsList = () => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'artists' })

  const breadcrumbs = [
    { href: '', name: translateResource('title') }
  ]

  const [open, setOpen] = useState(false)

  const queryClient = useQueryClient()
  const collectionDefinition = useApiResource('artists')
  const navigate = useNavigate()

  const onReload = () => {
    queryClient.refetchQueries({ queryKey: ['artists'] }).catch(captureException)
  }

  const onCreateClick = () => {
    setOpen(true)
  }

  const onEditClick = (entry) => {
    navigate(`/artists/${entry.uid}`)
  }

  const onEdit = () => {
    onReload()
    setOpen(false)
  }

  return (
    <Layout description={translateResource('description')} title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={PaintBrushIcon} title={translateResource('title')} />

        <ApiResource createdAt={'desc'} customFieldsToDisplay={['fullName', 'nationality', 'createdAt']} name={'artists'} onClick={onEditClick} withCreateOption={onCreateClick} withDeleteOption withEditOption={onEditClick} />

        <Modal center open={open} setOpen={setOpen} size='large' title={translateResource('create')}>
          {collectionDefinition && <ApiResourceCreate definition={collectionDefinition} isModal onEdit={onEdit} />}
        </Modal>
      </Guard>
    </Layout>
  )
}

export default ArtistsList
