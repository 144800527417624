interface SearchMapField {
  path: string
  searchField: string
}
const SearchMap: Record<string, SearchMapField> = {
  activity: {
    path: 'activities',
    searchField: 'name'
  },
  artist: {
    path: 'artists',
    searchField: 'fullName'
  },
  artwork: {
    path: 'artworks',
    searchField: 'name'
  },
  category: {
    path: 'categories',
    searchField: 'name'
  },
  city: {
    path: 'cities',
    searchField: 'name'
  },
  experience: {
    path: 'experiences',
    searchField: 'name'
  },
  experiences: {
    path: 'experiences',
    searchField: 'name'
  },
  hrHotel: {
    path: 'hotels',
    searchField: 'name'
  },
  partner: {
    path: 'partners',
    searchField: 'companyName'
  },
  product: {
    path: 'products',
    searchField: 'name'
  },
  tags: {
    path: 'hotel-tags',
    searchField: 'name'
  },
  tariffGrid: {
    path: 'partner-tariff-grids',
    searchField: 'name'
  },
  video: {
    path: 'videos',
    searchField: 'originalName'
  }
}

export default SearchMap
