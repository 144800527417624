import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ErrorMessage, Field, useField } from 'formik'
import React, { useMemo, useState } from 'react'

import { AutocompleteEntity } from '@components/autocomplete-entity'
import IconButton from '@components/buttons/icon-button'
import { type FormArrayAutocompleteFieldInterfaces } from '@components/form-fields/form-array-autocomplete-field'
import type { ApiPlatformEntity, ApiPlatformListResponse } from '@interfaces/api'
import SearchMap from '@services/api/definition/search-map'
import useApiResourceListQuery from '@services/api/resources/list-query'
import { captureException } from '@services/exceptions/capture-exception'
import { formatApiListResponse } from '@services/tools/api-resources/format-list-response'

const FormArrayAutocompleteField = <T extends ApiPlatformEntity>({
  emptyStateLabel,
  label,
  name,
  placeholder,
  selectedItems
}: FormArrayAutocompleteFieldInterfaces) => {
  const [field, , helpers] = useField<string[]>({ name })
  const [selectedOption, setSelectedOption] = useState<T | null>(null)
  const [arrayItems, setArrayItems] = useState<T[]>(selectedItems)
  const { path = '', searchField = '' } = SearchMap[name] ?? {}
  const [query, setQuery] = useState('')

  const definition = {
    name: path,
    url: ''
  }

  const {
    data = [] as unknown as ApiPlatformListResponse<T>
  } = useApiResourceListQuery<T>({
    definition,
    parameters: {
      pagination: false
    }
  })

  const items = useMemo(() => {
    const formattedData = formatApiListResponse(data)?.data

    // Exclure les éléments déjà sélectionnés
    const filteredData = formattedData?.filter(
      (item) => !arrayItems.some((selected) => selected.uid === item.uid)
    )

    // Appliquer le filtrage par recherche
    return filteredData?.filter((item) =>
      item[searchField].toLowerCase().includes(query.toLowerCase())
    )
  }, [data, query, arrayItems])

  const { value: arrayIds = [] } = field
  const { setValue } = helpers

  const handleAdd = () => {
    const itemToAdd = items.find((item) => item.uid === selectedOption?.uid)
    if (itemToAdd && !arrayIds.includes(itemToAdd['@id'])) {
      setArrayItems((prev) => [...prev, itemToAdd])
      setValue([...arrayIds, itemToAdd['@id']]).catch(captureException)
      setSelectedOption(null)
      setQuery('')
    }
  }

  const handleRemove = (id: string) => {
    setArrayItems((prev) => prev.filter((item) => item['@id'] !== id))
    setValue(arrayIds.filter((itemId) => itemId !== id)).catch(captureException)
  }

  return (
    <div className='flex flex-col w-full'>
      <div className='flex items-end mb-4'>
        <AutocompleteEntity
          displayKey={searchField}
          emptyStateLabel={emptyStateLabel}
          items={items}
          label={label}
          onChange={(item) => {
            setSelectedOption(item)
          }}
          placeholder={placeholder}
          query={query}
          required={false}
          selectedOption={selectedOption}
          setQuery={setQuery}
        />

        <button
          className='ml-2 flex items-center rounded-md p-2 bg-gray-800 hover:bg-gray-500 text-white'
          onClick={handleAdd}
          type='button'
        >
          <PlusIcon className='w-5 h-5' />
        </button>
      </div>

      {arrayItems.length > 0
        ? (
          <ul className='list-none space-y-2'>
            {arrayItems.map((item) => (
              <li
                className='flex items-center justify-between px-4 py-2 bg-gray-100 rounded-lg'
                key={item.uid}
              >
                <span>{item[searchField]}</span>

                <IconButton
                  icon={TrashIcon}
                  onClick={() => {
                    handleRemove(item['@id'])
                  }}
                  size={4}
                  style='warning'
                />
              </li>
            ))}
          </ul>
        )
        : (
          <p className='text-gray-400'>{emptyStateLabel ?? 'No items added yet.'}</p>
        )}

      <Field name={name} type='hidden' />

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormArrayAutocompleteField
