import { PlusCircleIcon, StarIcon } from '@heroicons/react/24/outline'
import { pluralize } from 'inflection'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import ApiResourceCreate from '@components/api-resource/api-resource-create'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import ContextualButton from '@components/buttons/contextual-button'
import ExperiencesTable from '@components/experiences-table'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import SectionTitle from '@components/section-title/section-title'
import { ExperienceType } from '@interfaces/api/experience'
import useApiResource from '@services/api/definition'

const ExperiencesList = () => {
  const location = useLocation()
  const [experienceType, setExperienceType] = useState(location.state?.experienceType || 'attraction')
  const { type } = useParams()
  const [open, setOpen] = useState(location.state?.open || false)
  const collectionDefinition = useApiResource(pluralize(experienceType))

  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'experiences' })
  const { t: translateSubResource } = useTranslation('apiResources', { keyPrefix: `experiences.${experienceType}` })

  const navigate = useNavigate()

  const breadcrumbs = [
    { href: '', name: translateResource('title') }
  ]

  const afterSubmit = (data) => {
    setOpen(false)
    navigate(`/experiences/${experienceType}/${data?.uid}`)

    return data
  }

  const onCreate = () => {

  }

  const onCreateClick = async (type: string) => {
    setExperienceType(type)
    setOpen(true)
  }

  return (
    <Layout description={translateResource('description')} title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={StarIcon} title={translateResource('title')} />

        <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8 w-full'>
          <div className='flex space-x-2 items-center justify-end mb-4'>
            {Object.values(ExperienceType).map(type => (
              <ContextualButton
                icon={PlusCircleIcon}
                onClick={async () => {
                  await onCreateClick(type)
                }}
              >
                {translateResource(`types.${type}`)}
              </ContextualButton>
            ))}
          </div>

          <div className='border-b border-gray-200 bg-white rounded-lg shadow py-4'>
            <ExperiencesTable path='/experiences' type={type ? type as ExperienceType : ExperienceType.artwork} />
          </div>
        </div>

        <Modal center open={open} setOpen={setOpen} size='large' title={translateSubResource('create')}>
          {collectionDefinition && (
            <ApiResourceCreate
              afterSubmit={afterSubmit}
              definition={collectionDefinition}
              isModal
              onEdit={onCreate}
            />
          )}
        </Modal>

      </Guard>
    </Layout>
  )
}

export default ExperiencesList
