import { pluralize } from 'inflection'
import { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import ContextualButton from '@components/buttons/contextual-button'
import EmptyStateButton from '@components/empty-state-button/empty-state-button'
import { type EntryVideosProps } from '@components/entry-videos/entry-videos.interfaces'
import GlobalSearch from '@components/global-search/global-search'
import LangSelector from '@components/lang-selector/lang-selector'
import ModalVideoPlayer from '@components/modals/modal-video-player'
import VideoThumbnail from '@components/video/video-thumbnail/video-thumbnail'
import { type EntryVideo } from '@interfaces/api/entry-video'
import { type Video } from '@interfaces/api/video'
import useDeleteEntrySimple from '@services/api/resources/delete-entry-query-simple'
import useItemChildrenQuery from '@services/api/resources/item-children'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import usePatchEntrySimple from '@services/api/resources/patch-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'
import { LanguageCodes } from '@services/languages'

const EntryVideos = ({ entityName, id, onVideoSelect }: EntryVideosProps) => {
  const [open, setOpen] = useState(false)
  const [video, setVideo] = useState<Video>()
  const [isModalVideoOpen, setModalVideoOpen] = useState(false)
  const [language, setLanguage] = useState<LanguageCodes>(LanguageCodes.en)
  const [filteredVideos, setFilteredVideos] = useState<EntryVideo[]>([])
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })

  const { mutateAsync: deleteResourceEntry } = useDeleteEntrySimple()
  const { mutateAsync: patchResourceEntry } = usePatchEntrySimple()

  const {
    data: {
      data: videos
    } = {},
    refetch
  } = useItemChildrenQuery<EntryVideo>({
    itemId: id,
    path: `${pluralize(entityName)}/{uid}/videos`
  })

  const { data: videoData } = useApiResourceItemQuery<Video>({ id: video ? video.uid : '', path: 'videos/{uid}', refetchOnMount: 'always' })

  const onAddVideoClick = () => {
    setOpen(true)
  }

  const reload = () => {
    refetch().catch(captureException)
  }

  const onVideoSelected = (videos) => {
    videos.forEach((video) => {
      onVideoSelect(video, language, reload, setOpen)
    })
  }

  const showVideo = (video: Video) => {
    setVideo(video)
    setModalVideoOpen(true)
  }

  const deleteVideo = (uid: string) => {
    deleteResourceEntry({ id: uid, path: `${entityName}-videos/{uid}` }).then(() => {
      reload()
      setOpen(false)
    }).catch(captureException)
  }

  const onLangSelect = (lang: LanguageCodes) => {
    setLanguage(lang)
  }

  useEffect(() => {
    if (videos) {
      setFilteredVideos(videos.filter(video => video.lang === language))
    }
  }, [videos, language])

  const reorderVideos = (list: EntryVideo[], startIndex: number, endIndex: number): EntryVideo[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const newPriority = filteredVideos[result.destination.index].priority
    const items = reorderVideos(
      filteredVideos,
      result.source.index,
      result.destination.index
    )

    setFilteredVideos(items)

    patchResourceEntry({
      data: {
        priority: newPriority
      },
      id: result.draggableId,
      path: `${entityName}-videos/{uid}`
    }).then(() => {
      reload()
    }).catch(captureException)
  }

  return (
    <>
      <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-10'>
        <div className='px-4 py-4'>
          <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
            <div className='flex items-center justify-center space-x-6 ml-4 mt-4'>
              <h3 className='text-xl font-semibold leading-6 text-slate-700'>Vidéos</h3>
            </div>

            <div className='ml-4 mt-4 flex-shrink-0'>
              <ContextualButton onClick={onAddVideoClick}>
                {translateActions('addVideo')}
              </ContextualButton>
            </div>
          </div>
        </div>

        <div className='px-4'>
          <LangSelector onSelect={onLangSelect} />
        </div>

        {filteredVideos && filteredVideos.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable direction='horizontal' droppableId='droppable-videos'>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  className='py-3 px-4 w-full flex gap-2 overflow-x-scroll'
                  ref={provided.innerRef}
                >
                  {filteredVideos.map((entryVideo, index) => (
                    <Draggable draggableId={entryVideo.uid} index={index} key={entryVideo.uid}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <VideoThumbnail
                            isActive={snapshot.isDragging}
                            key={index}
                            onClick={showVideo}
                            onDeleteClick={() => {
                              deleteVideo(entryVideo.uid)
                            }}
                            video={entryVideo.video}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}

        {filteredVideos && filteredVideos.length <= 0 && (
          <div className='py-4 pb-8 px-4 w-full'>
            <EmptyStateButton onClick={onAddVideoClick} translateKey={'videos'} />
          </div>
        )}
      </div>

      <GlobalSearch
        excludedUids={filteredVideos.map(experienceVideo => experienceVideo.video.uid)}
        indexes={['video']}
        isOpened={open}
        multiple
        onSelectCallback={onVideoSelected}
        setOpened={setOpen}
      />

      {videoData && (
        <ModalVideoPlayer
          open={isModalVideoOpen}
          setOpen={setModalVideoOpen}
          video={videoData}
        />
      )}
    </>
  )
}

export default EntryVideos
