import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ApiResourceCreate from '@components/api-resource/api-resource-create'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import ContextualButton from '@components/buttons/contextual-button'
import DropdownButton from '@components/buttons/dropdown-button/dropdown-button'
import CityPagesTabs from '@components/cities/city-pages/city-pages-tabs'
import { type CityPagesProps } from '@components/cities/city-pages/city-pages.interfaces'
import FormTextField from '@components/form-fields/form-text-field'
import Modal from '@components/modals/modal'
import SkeletonLoaderTable from '@components/skeleton-loader/skeleton-loader-table'
import { type CityPage, CityPageHomeMobile, CityPageMenus, CityPageType } from '@interfaces/api/city/city-page'
import useApiResource from '@services/api/definition'
import useCreateEntry from '@services/api/resources/create-entry-query'
import useDeleteEntrySimple from '@services/api/resources/delete-entry-query-simple'
import useItemChildrenQuery from '@services/api/resources/item-children'
import usePatchEntrySimple from '@services/api/resources/patch-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'

const CityPages = ({ breadcrumbs, city, id }: CityPagesProps) => {
  const { t: translateResources } = useTranslation('apiResources', { keyPrefix: 'pages' })
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const { t } = useTranslation('apiResources')
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [newPageType, setNewPageType] = useState<CityPageType.TABS | CityPageType.SECTIONS>()
  const [page, setPage] = useState<CityPage>()
  const [cityPages, setCityPages] = useState<CityPage[]>([])
  const [currentTab, setCurrentTab] = useState<CityPageMenus | string>(CityPageMenus.TAB_BAR)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const collectionDefinition = useApiResource('pages')
  const definition = useApiResource('pages/{uid}')
  const cityPageDefinition = useApiResource('city-pages/{uid}')

  const cityPageExtraFields = cityPageDefinition?.methods.patch.getRequestFields(['slug'])

  const { mutateAsync: createCityPage } = useCreateEntry({ headers: { 'Content-Type': 'application/json' }, path: 'city-pages' })
  const { mutateAsync: deletePage } = useDeleteEntrySimple()
  const { mutateAsync: patchResourceEntry } = usePatchEntrySimple()

  const {
    data: {
      data: cityPagesData
    } = {},
    isFetching,
    refetch
  } = useItemChildrenQuery<CityPage>({
    itemId: id,
    path: 'cities/{uid}/pages'
  })

  useEffect(() => {
    if (cityPagesData) {
      let filteredCityPages: CityPage[] = []
      if (currentTab === CityPageMenus.TAB_BAR) {
        filteredCityPages = cityPagesData.filter(cityPage => [CityPageType.ACTIVITIES, CityPageType.TOURS].includes(cityPage.type))
      } else if (currentTab === CityPageMenus.HOME_MOBILE) {
        filteredCityPages = cityPagesData.filter(cityPage => CityPageHomeMobile.includes(cityPage.type))
      } else if (currentTab === CityPageMenus.TABS) {
        filteredCityPages = cityPagesData.filter(cityPage => [CityPageType.TABS].includes(cityPage.type))
      } else {
        filteredCityPages = cityPagesData.filter(cityPage => [CityPageType.SECTIONS].includes(cityPage.type))
      }
      setCityPages(filteredCityPages)
    }
  }, [cityPagesData, currentTab])

  const onRowClick = (cityPage) => {
    navigate(`/pages/${cityPage.page.uid}/${id}/${cityPage.type ?? CityPageType.SECTIONS}`, { state: { breadcrumbs } })
  }

  const onEditClick = (cityPage) => {
    setPage(cityPage)
    setOpenEdit(true)
  }

  const onEdit = async () => {
    await refetch()
    setOpenEdit(false)
  }

  const onDeleteClick = (cityPage) => {
    setOpenDeleteModal(true)
    setPage(cityPage)
  }

  const onDeleteHandler = () => {
    if (page) {
      deletePage({ id: page.uid, path: 'city-pages/{uid}' }).then(async () => await refetch()).catch(captureException)
    }
    setOpenDeleteModal(false)
  }

  const handleCancelClick = () => {
    setOpenDeleteModal(false)
  }

  const onPageAddClick = async (type) => {
    if (type as CityPageType === CityPageType.SECTIONS || type as CityPageType === CityPageType.TABS) {
      setOpen(true)
      setNewPageType(type)
    } else {
      const cityPageData = {
        city: city['@id'],
        type
      }

      await createCityPage(cityPageData)
      await refetch()
    }
  }

  const onCreate = async () => {
    await refetch()

    if (newPageType) {
      setCurrentTab(newPageType)
    }
    setOpen(false)
  }

  const afterSubmit = async (data, submittedData) => {
    const cityPageData = {
      city: city['@id'],
      page: data['@id'],
      slug: submittedData.slug,
      type: newPageType
    }

    await createCityPage(cityPageData)
    await refetch()
  }

  const beforeSubmit = async (data) => {
    data.city = city['@id']

    return data
  }

  const getDropdownOptions = () => {
    if (cityPagesData && cityPagesData.length > 0) {
      const typesInCityPages = cityPagesData.map(page => page.type)

      return Object.values(CityPageType).filter(type =>
        type === CityPageType.SECTIONS || type === CityPageType.TABS || !typesInCityPages.includes(type)
      )
    }

    return Object.values(CityPageType)
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const sourceIndex = result.source.index
    const destinationIndex = result.destination.index
    const newPriority = cityPages[result.destination.index].priority

    if (sourceIndex === destinationIndex) {
      return
    }

    const newPages = Array.from(cityPages)
    const [removed] = newPages.splice(sourceIndex, 1)

    newPages.splice(destinationIndex, 0, removed)
    setCityPages(newPages)

    patchResourceEntry({
      data: {
        priority: newPriority
      },
      id: result.draggableId,
      path: 'city-pages/{uid}'
    }).then(() => {
      // reload()
    }).catch(captureException)
  }

  const handleBeforeEdit = (data) => {
    const { slug, ...rest } = data

    if (slug && page) {
      patchResourceEntry({
        data: {
          slug
        },
        id: page.uid,
        path: 'city-pages/{uid}'
      }).catch(captureException)
    }

    return rest
  }

  return (
    <>
      <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-10'>
        <div className='px-4 py-4'>
          <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
            <div className='ml-4 mt-4'>
              <h3 className='text-xl font-semibold leading-6 text-slate-700'>Pages</h3>
            </div>

            <div className='ml-4 mt-4 flex-shrink-0'>
              <DropdownButton onCreateClick={onPageAddClick} options={getDropdownOptions()} ressource='pages' />
            </div>
          </div>
        </div>

        <CityPagesTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />

        {cityPages.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable direction='vertical' droppableId='droppable'>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  className='flow-root'
                  ref={provided.innerRef}
                >
                  <div className='w-screen sm:w-auto'>
                    <div className={'min-w-min w-full table-wrp block'}>
                      <table className='w-full divide-y'>
                        <thead className='bg-gray-50 text-gray-900 sticky top-0'>
                          <tr>
                            <th className='p-3 text-left'>
                              <div className='flex items-center text-base font-medium'>
                                Nom
                              </div>
                            </th>

                            <th />
                          </tr>
                        </thead>

                        <tbody className='bg-white min-h-[400px] mb-20 divide-y divide-gray-100'>
                          {isFetching
                            ? (
                              <SkeletonLoaderTable headers={[]} />
                            )
                            : (
                              <>
                                {cityPages.map((cityPage, index) => (
                                  <Draggable draggableId={cityPage.uid} index={index} key={cityPage.uid}>
                                    {(provided, snapshot) => (
                                      <tr
                                        className='flex bg-white hover:bg-gray-100 cursor-pointer text-sm'
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          onRowClick(cityPage)
                                        }}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        key={cityPage.uid}
                                      >
                                        <td className='px-3 py-1.5 flex w-full gap-3 items-center justify-between cursor-pointer'>
                                          {cityPage.page.title
                                            ? (
                                              <>
                                                {cityPage.page.title}
                                              </>
                                            )
                                            : <>{translateResources(`types.${cityPage.type}`)}</>
                                          }

                                          <div className='flex space-x-2'>
                                            <button className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white' onClick={(e) => {
                                              e.stopPropagation()
                                              onEditClick(cityPage)
                                            }}
                                            >
                                              <PencilSquareIcon className='w-5 h-5 mx-auto' />
                                            </button>

                                            {(cityPage.type === CityPageType.SECTIONS || cityPage.type === CityPageType.TABS) && (
                                              <button className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white' onClick={(e) => {
                                                e.stopPropagation()
                                                onDeleteClick(cityPage)
                                              }}
                                              >
                                                <TrashIcon className='w-5 h-5 mx-auto' />
                                              </button>
                                            )}
                                          </div>

                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>

      <Modal center open={open} setOpen={setOpen} size='large' title={translateResources('create')}>
        {collectionDefinition && (
          <ApiResourceCreate afterSubmit={afterSubmit} beforeSubmit={beforeSubmit} definition={collectionDefinition} extraFields={cityPageExtraFields} fieldsToHide={['city']} isModal onEdit={onCreate}>
            <FormTextField key={'slug'} label={t(['labels.cityPages.slug', 'labels.slug'])} name={'slug'} placeholder={''} required />
          </ApiResourceCreate>
        )}
      </Modal>

      {page && (
        <Modal center open={openEdit} setOpen={setOpenEdit} size='large' title={translateResources('edit')}>
          {definition && (
            <ApiResourceEdit beforeEdit={handleBeforeEdit} definition={definition} excludedFields={['city']} extraFields={cityPageExtraFields} extraValues={{ slug: page.slug }} id={page.page.uid} isModal onEdit={onEdit}>
              <FormTextField key={'slug'} label={t(['labels.cityPages.slug', 'labels.slug'])} name={'slug'} placeholder={''} required />
            </ApiResourceEdit>
          )}
        </Modal>
      )}

      <Modal center open={openDeleteModal} setOpen={setOpenDeleteModal} title={translateActions('deleteResource')}>
        <div className='flex flex-col items-center p-8'>
          <div className='text-center text-xl'>{translateActions('deleteConfirmation')}</div>

          <div className='flex mt-6 gap-8'>
            <ContextualButton onClick={onDeleteHandler} style='warning'>{translateActions('delete')}</ContextualButton>

            <ContextualButton onClick={handleCancelClick}>{translateActions('cancel')}</ContextualButton>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CityPages
