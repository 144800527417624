import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useParams } from 'react-router-dom'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import { type Breadcrumb } from '@components/breadcrumbs/breadcrumbs-interfaces'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import SectionList from '@components/pages/section-list'
import { AttractionPageType } from '@interfaces/api/experience/experience-page'
import { type Page } from '@interfaces/api/page'
import useApiResourceItemQuery from '@services/api/resources/item-query'

const PageEntryPage = () => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'pages' })
  const { id = '', slug } = useParams()
  const { data } = useApiResourceItemQuery<Page>({ id, path: 'pages/{uid}', refetchOnMount: 'always' })
  const location = useLocation()
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])

  useEffect(() => {
    if (location.state?.breadcrumbs) {
      setBreadcrumbs([
        ...location.state?.breadcrumbs,
        { href: `/pages/${id}/${slug}`, name: translateResource(`types.${slug}`) }
      ])
    } else {
      setBreadcrumbs([
        { href: '/', name: translateResource('title') },
        { href: `/pages/${id}/${slug}`, name: translateResource(`types.${slug}`) }
      ])
    }
  }, [])

  return (
    <Layout description={translateResource('description')} title={`${translateResource('title')} - ${translateResource(`types.${slug}`)}`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8 w-full pb-6'>

          {data && (
            <>
              <div className='lg:flex lg:items-center lg:justify-between gap-6'>

                <div className='min-w-0 flex-1'>
                  <h2 className='text-2xl font-bold leading-7 text-slate-700 sm:truncate sm:text-3xl sm:tracking-tight'>
                    {slug === AttractionPageType.DEFAULT ? data.title : translateResource(`types.${slug}`)}
                  </h2>
                </div>
              </div>

              <div className='border-b border-gray-200 bg-white rounded-lg shadow my-10'>
                <div className='px-4 py-5 sm:px-6'>
                  <div className='-ml-4 -mt-4 flex flex-col flex-wrap sm:flex-nowrap'>
                    <div className='ml-4 mt-4'>
                      <h3 className='text-xl font-semibold leading-6 text-slate-700'>Sections</h3>
                    </div>

                    <div className='ml-4 mt-4 flex-shrink-0'>
                      <SectionList pageId={id} />
                    </div>
                  </div>
                </div>

              </div>
            </>
          )}
        </div>
      </Guard>
    </Layout>
  )
}

export default PageEntryPage
