import { PencilSquareIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Modal from '@components/modals/modal'
import { type ProductPriceOptionsProps } from '@components/products/product-price-options/product-price-options.interfaces'
import { type ProductPriceOption } from '@interfaces/api/product/product-price-option'
import useApiResource from '@services/api/definition'

const ProductPriceOptions = ({ product, reload }: ProductPriceOptionsProps) => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'product-price-options' })

  const [priceOption, setPriceOption] = useState<ProductPriceOption>()

  const [openEdit, setOpenEdit] = useState(false)
  const definition = useApiResource('product-price-options/{uid}')

  const onEdit = async () => {
    reload()
    setOpenEdit(false)
  }

  const onEditClick = (priceOption: ProductPriceOption) => {
    setPriceOption(priceOption)
    setOpenEdit(true)
  }

  return (
    <>
      <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-10 mb-10'>
        <div className='px-4 py-4'>
          <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
            <div className='ml-4 mt-4'>
              <h3 className='text-xl font-semibold leading-6 text-slate-700'>Price options</h3>
            </div>
          </div>
        </div>

        {product.priceOptions && product.priceOptions.length > 0 && (
          <div className='w-screen sm:w-auto'>
            <div className={'min-w-min w-full table-wrp block'}>
              <table className='w-full divide-y'>
                <thead className='bg-gray-50 text-gray-900 sticky top-0'>
                  <tr>
                    <th className='p-4 text-left'>
                      <div className='flex items-center text-base font-medium'>
                        Nom
                      </div>
                    </th>

                    <th className='p-4 text-left'>
                      <div className='flex items-center text-base font-medium'>
                        Prix
                      </div>
                    </th>

                    <th className='p-4 text-left'>
                      <div className='flex items-center text-base font-medium'>
                        Sièges utilisés
                      </div>
                    </th>

                    <th />
                  </tr>
                </thead>

                <tbody>
                  {product.priceOptions.map((priceOption) => (
                    <tr
                      className='bg-white hover:bg-gray-100 cursor-pointer px-4 py-2'
                      key={priceOption.uid}
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      <td className='p-4 text-sm font-bold'>
                        {priceOption.name}
                      </td>

                      <td className='p-4 text-sm'>
                        {`${(priceOption.price / 100).toFixed(2)} €`}
                      </td>

                      <td className='p-4 text-sm'>
                        {`${priceOption.seatsUsed} seats`}
                      </td>

                      <td className='p-4'>
                        <button
                          className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white'
                          onClick={(e) => {
                            e.stopPropagation()
                            onEditClick(priceOption)
                          }}
                        >
                          <PencilSquareIcon className='w-5 h-5 mx-auto' />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      {priceOption && (
        <Modal center open={openEdit} setOpen={setOpenEdit} size='large' title={translateResource('edit')}>
          {definition && <ApiResourceEdit definition={definition} id={priceOption.uid} isModal onEdit={onEdit} />}
        </Modal>
      )}
    </>
  )
}

export default ProductPriceOptions
